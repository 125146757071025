







import { Component, Prop, Vue } from 'vue-property-decorator'
import AddCheckout from '@/components/AddCheckout.vue'

@Component({
  components: {
    AddCheckout,
  },
})
export default class Checkout extends Vue {
  @Prop() intentId?: string
}
